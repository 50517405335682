import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import StarRating from "../components/StarRating";
import { useCartContext } from '../context/cart_context';

const Course = (props) => {
  const { id, image, course_name, creator, actual_price, discounted_price, rating_count, rating_star, category, duration, CourseId } = props;
  const { addToCart } = useCartContext();

  return (
    <CourseCard>
      <div className="item-img">
        <img src={image} alt={course_name} />
      </div>
      <div className="item-body">
        <h5 className="item-name">{course_name}</h5>
        <span className="item-creator">{creator}</span>
        <div className="rating">
          <StarRating rating_star={rating_star} />
          <span className="rating-count">({rating_count})</span>
        </div>
        <span className="item-duration">Duration: {duration}</span>
        <div className="item-price">
          <span className="item-price-new">Rs. {discounted_price}</span>
          <span className="item-price-old">Rs. {actual_price}</span>
        </div>
      </div>
      <div className="item-btns flex">
        <Link to={`/courses/${id}`} className="item-btn see-details-btn">See details</Link>
        <Link to="/cart" className="item-btn add-to-cart-btn" onClick={() => addToCart(id, image, course_name, creator, discounted_price, category, CourseId)}>Add to cart</Link>
      </div>
    </CourseCard>
  );
}

const CourseCard = styled.div`
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  .item-img img {
    width: 100%;
    max-height: 200px; 
    object-fit: cover;
    transition: transform 0.3s ease;
    border-bottom: 1px solid #ddd;

    &:hover {
      transform: scale(1.05);
    }
  }

  .item-body {
    padding: 14px 18px;
    flex-grow: 1;

    .item-name {
      font-size: 18px;
      line-height: 1.4;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .item-creator {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 5px;
    }

    .rating {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    .rating-count {
      font-size: 13px;
      margin-left: 5px;
      opacity: 0.7;
    }

    .item-duration {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 10px;
    }

    .item-price {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .item-price-new {
        font-weight: 700;
        font-size: 16px;
        color: #28a745;
      }

      .item-price-old {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        text-decoration: line-through;
      }
    }
  }

  .item-btns {
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
    gap: 10px;

    .item-btn {
      padding: 8px 16px;
      font-weight: 700;
      font-size: 14px;
      transition: all 0.3s ease;
      white-space: nowrap;
      border-radius: 4px;
      text-align: center;

      &.see-details-btn {
        background-color: transparent;
        border: 1px solid #007bff;
        color: #007bff;

        &:hover {
          background-color: #007bff;
          color: white;
        }
      }

      &.add-to-cart-btn {
        background-color: #007bff;
        color: white;
        border: 1px solid #007bff;

        &:hover {
          background-color: transparent;
          color: #007bff;
        }
      }
    }
  }
`;

export default Course;
